import type { ContentChatbot, ChatMessage } from '~/models/Content/ContentChatbot'
import { computed, ref } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { ContentType } from '~/models/Content/ContentType'
import useContentApi from '~/api/contentApi'

interface UseChatbotOptions {
  locationId: number
  maxRecall?: number
}

interface StreamMessage {
  result: { output: { content: string } }
}

export default (options: UseChatbotOptions) => {
  const { locationId, maxRecall } = { ...{ maxRecall: 5 }, ...options }

  const { findContents } = useContentApi()

  const chatHistory = ref<ChatMessage[]>([])
  const isStreaming = ref(false)

  const chatContinuation = computed(() =>
    chatHistory.value.slice((Math.min(maxRecall, chatHistory.value.length) * -1))
      .map((m) => ({ [m.from]: m.message }))
  )

  const findBot = (locationId: number) => useQuery({
    staleTime: 3600,
    queryKey: ['chatbot', locationId],
    queryFn: async () => (await findContents<ContentChatbot>({
      locationIdCriterion: [locationId],
      contentTypeCriterion: [ContentType.Chatbot],
    }, 1))[0]
  })

  const { data: bot, isLoading } = findBot(locationId)

  const systemInstructions = computed(() => ({
    system: bot ? bot.value?.prompt : '',
  }))

  async function* getIterableStream<T>(body: ReadableStream<Uint8Array>): AsyncIterable<T> {
    const reader = body.getReader()
    const decoder = new TextDecoder()
    isStreaming.value = true
    while(true) {
      const { value, done } = await reader.read()
      if (done) {
        isStreaming.value = false
        break
      }
      const decoded = decoder.decode(value).split('\n')
      for (let i = 0; i < decoded.length; i++) {
        try {
          yield JSON.parse(decoded[i])
        } catch (e) {
          // skip, likely not a complete JSON object yet
        }
      }
    }
  }

  const generateStream = async <T>(value: string): Promise<AsyncIterable<T>> => {
    const response = await fetch(`${import.meta.env.VITE_CORE_KI_API_URL}/stream/chat`, {
      method: 'POST',
      headers: {
        'accept': 'application/x-ndjson',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify([systemInstructions.value, ...chatContinuation.value, { user: value }]),
    })

    if (response.status !== 200) throw new Error(response.status.toString())
    if (!response.body) throw new Error('Response body does not exist')
    return getIterableStream<T>(response.body)
  }

  const prompt = async (value: string, initial = false) => {
    if (!value) return

    const stream = await generateStream<StreamMessage>(value)

    if (initial) {
      chatHistory.value = [...chatHistory.value, { from: 'assistant', message: '' }]
    } else {
      chatHistory.value = [...chatHistory.value, { from: 'user', message: value }, { from: 'assistant', message: '' }]
    }

    for await (const chunk of stream) {
      chatHistory.value = chatHistory.value.map((m, i) => {
        if (i === chatHistory.value.length - 1) m.message += chunk.result.output.content
        return m
      })
    }
  }

  return {
    bot,
    isLoading,
    isStreaming,
    chatHistory,
    maxRecall,
    findBot,
    prompt,
  }
}
