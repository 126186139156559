<script setup lang="ts">
import { watch } from 'vue'
import useChatBot from '~/composables/useChatbot'
import Chatbot from '~/components/chat/Chatbot.vue'

const props = defineProps<{ locationId: number; prompt: string }>()

const { bot, isLoading, isStreaming, chatHistory, maxRecall, prompt } = useChatBot({ locationId: props.locationId })

watch(bot, () => {
  if (!bot.value) return
  prompt(props.prompt, true)
}, { immediate: true })
</script>

<template>
  <Chatbot
    class="my-8 w-full rounded-xl bg-[#ffead1ff] p-6 font-inter max-md:px-4 sm:mx-4 lg:mx-auto"
    v-bind="{ bot, isLoading, isStreaming, chatHistory, maxRecall, prompt } "
  />
</template>
