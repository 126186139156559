<script lang="ts" setup>
import type { ContentChatbot, ChatMessage } from '~/models/Content/ContentChatbot'
import { nextTick, ref, useTemplateRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { faPaperPlaneTop } from '@fortawesome/pro-regular-svg-icons'
import { KsButton, KsIcon } from '@aschehoug/kloss'

const props = defineProps<{
  bot?: ContentChatbot
  isLoading: boolean
  isStreaming: boolean
  chatHistory: ChatMessage[]
  maxRecall: number
  prompt: (value: string, initial?: boolean) => Promise<void>
}>()

const inputValue = ref<string>('')

const { t } = useI18n()

const submit = () => {
  props.prompt(inputValue.value)
  inputValue.value = ''
}

const input = useTemplateRef('chatInput')

watch(props, () => {
  if (props.isStreaming || !input.value) return
  nextTick(() => {
    input?.value?.focus()
  })
})
</script>

<template>
  <section class="mx-auto h-full motion-safe:animate-fade">
    <div
      v-if="isLoading"
      v-text="t('loading')"
    />
    <div
      v-else
      class="grid h-full min-h-0 min-w-0 grid-rows-[auto,max-content] gap-4"
    >
      <div class="min-h-0 min-w-0 overflow-y-scroll">
        <div class="grid auto-rows-max gap-4">
          <template
            v-for="(message, index) in chatHistory"
            :key="index"
          >
            <div
              class="message relative min-w-96 rounded-lg p-4 shadow-md"
              :class="[
                message.from,
                (message.from === 'assistant' ? 'justify-self-start rounded-bl-none bg-orange-20/80 sm:mr-8 md:mr-10' : ''),
                (message.from === 'user' ? 'justify-self-end rounded-br-none bg-white sm:ml-8 md:ml-10' : ''),
              ]"
            >
              <div v-text="message.message" />
            </div>
            <div
              v-if="((chatHistory.length - 1) - index) === maxRecall"
              class="text-cursive justify-self-center text-gray-30"
              v-text="t('chatbot.remember')"
            />
          </template>
        </div>
      </div>
      <div class="grid gap-4 sm:grid-cols-1 md:grid-cols-[auto,max-content]">
        <input
          ref="chatInput"
          v-model="inputValue"
          class="w-full rounded-md border-2 border-gray-30 px-2 py-1"
          type="text"
          :disabled="isLoading || isStreaming"
          @keydown.enter="submit"
        >
        <KsButton
          variant="tertiary"
          size="large"
          shape="square"
          :disabled="!inputValue.length || isLoading || isStreaming"
          @click.prevent="submit"
        >
          <KsIcon :icon="faPaperPlaneTop" />
        </KsButton>
      </div>
    </div>
  </section>
</template>
