<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { faArrowLeft, faUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'
import { KsButton, KsIcon } from '@aschehoug/kloss'
import useLicenseControlStore from '~/stores/licenseControl'
import { useAuthStore } from '~/stores/auth'
import usePaywallOverlay from '~/composables/usePaywallOverlay'
import { useSubscription } from '~/composables/useSubscription'
import useSubscriptionsStore from '~/stores/subscriptions'
import useContentApi from '~/api/contentApi'
import { useQuery } from '@tanstack/vue-query'
import arrayUtils from '~/utils/arrayUtils'
import { ContentType } from '~/models/Content/ContentType'
import type { ContentProduct } from '~/models/Content/ContentProduct'
import useProductHelper from '~/composables/useProductHelper'

const { t } = useI18n()
const licenseControlStore = useLicenseControlStore()
const { isTeacher } = storeToRefs(useAuthStore())
const { resetLicenseControlStore } = licenseControlStore
const { hasAccessRestricted, locationPath } = storeToRefs(licenseControlStore)
const { washPage } = usePaywallOverlay()
const { isTrial, isBasic, isIndividual, isPlus } = useSubscription()
const { relevantSubscription } = storeToRefs(useSubscriptionsStore())
const { findContents } = useContentApi()
const { truthy } = arrayUtils()
const { isPlusProduct, isLibrary, isExam, isPaperPlus } = useProductHelper()

const webshopUrl = computed(() => <string>import.meta.env.VITE_WEBSHOP_TRIALURL)

// Not loaded by licenses, lookup needed
const pathLocationIds = computed(() =>
  locationPath.value
    ? locationPath.value
        .split('/')
        .map((id) => Number(id))
        .filter(truthy)
        .sort((a, b) => a - b)
        .slice(3)
    : []
)

const { data: product } = useQuery({
  staleTime: Infinity,
  queryKey: ['product-lookup', pathLocationIds.value],
  enabled: pathLocationIds.value.length > 0,
  queryFn: () => findContents<ContentProduct>({
    locationIdCriterion: pathLocationIds.value,
    contentTypeCriterion: [ContentType.Product],
  }, 1).then(([data]) => data)
})

const back = () => {
  resetLicenseControlStore()
  window.location.href = '/'
}

const upgradeMessage = computed( (): string => {
  if (!relevantSubscription.value) return t('licenseControl.message.upgrade.noSubscription')
  if (isTrial(relevantSubscription.value)) return t('licenseControl.message.upgrade.trial')
  if ((isBasic(relevantSubscription.value) || isIndividual(relevantSubscription.value)) &&
    (product.value && isPlusProduct(product.value))) {
    return t('licenseControl.message.upgrade.why')
  }
  return ''
})

const productMessage = computed( (): string => {
  if (!product.value || !isPlusProduct(product.value)) return ''
  if (isLibrary(product.value)) return t('licenseControl.message.product.library')
  if (isExam(product.value)) return t('licenseControl.message.product.exam')
  if (isPaperPlus(product.value)) return t('licenseControl.message.product.paperPlus')
  return ''
})
</script>

<template>
  <Transition
    v-if="hasAccessRestricted"
    tag="div"
    class="paywall-overlay-ignore"
    enter-active-class="duration-500 delay-500"
    enter-from-class="translate-y-full opacity-0"
    enter-to-class="translate-y-0 opacity-100"
    appear
    paywall-overlay-ignore
    @after-enter="washPage"
  >
    <div class="absolute h-screen w-screen bg-gradient-to-b from-transparent via-white to-white">
      <div class="container mx-auto h-screen">
        <div class="absolute inset-x-0 bottom-10 z-20 m-auto h-fit max-w-prose rounded-lg border-gray-10 bg-white p-10 text-center sm:bottom-32 sm:border-2">
          <div class="mb-3 inline-flex rounded-full bg-seagreen-10/50 p-2 text-seagreen-50">
            <KsIcon
              icon="lock"
              :scale="1.8"
            />
          </div>
          <h3
            class="text-2xl font-bold"
            v-text="t('licenseControl.message.title')"
          />
          <p
            v-if="!isTeacher"
            class="mb-5 mt-3"
            v-text="t('licenseControl.message.body.student')"
          />
          <p
            v-if="isTeacher"
            class="mb-5 mt-3"
            v-text="upgradeMessage"
          />
          <p
            v-if="isTeacher"
            class="mb-5 mt-3"
            v-html="productMessage"
          />
          <p
            v-if="isTeacher && upgradeMessage"
            class="mb-5 mt-3"
            v-text="t('licenseControl.message.upgrade.who')"
          />
          <div
            class="grid w-full grid-cols-1 justify-center gap-4"
            :class="{ 'sm:grid-cols-2': isTeacher }"
          >
            <KsButton
              href="/"
              variant="primary"
              size="medium"
              shape="normal"
              :icon-left="faArrowLeft"
              center
              @click.prevent="back"
            >
              {{ t('licenseControl.buttons.back') }}
            </KsButton>
            <KsButton
              v-if="isTeacher"
              variant="secondary"
              size="medium"
              :href="webshopUrl"
              :icon-left="faUpRightFromSquare"
              target="_blank"
              center
            >
              {{ t('licenseControl.buttons.webshop') }}
            </KsButton>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<style>
.paywall-overlay-ignore {
  z-index: 300001 !important; /* more than Pendo guides which is 300000 */
}
</style>
