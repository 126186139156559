import type { SubscriptionDetails } from '~/models/Subscription'
import type { LicenseDetails } from '~/models/License'
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import arrayUtils from '~/utils/arrayUtils'
import useLicenseStore from '~/stores/license'
import { StaticSubscriptions } from '~/models/Subscription'
import useTime from '~/composables/useTime'

export function useSubscription() {
  const { uniqueBy, unique } = arrayUtils()
  const { isoStringInFuture } = useTime()

  const {
    loadLicenses,
    getLatestEndDateByEan,
  } = useLicenseStore()

  const {
    validLicenses
  } = storeToRefs(useLicenseStore())

  const isLoading = ref(false)

  const setSubscriptionsDetails = async () => {
    isLoading.value = true
    try {
      await loadLicenses()
      const subscriptionSet = [...Object.values(StaticSubscriptions).map(sub => sub.ean)]
      const licencesSet: LicenseDetails[] = [...validLicenses.value]
      const subscriptionLicenses = licencesSet
        .filter(license => subscriptionSet.includes(license.ean))
        .filter(license => isoStringInFuture(license.endDate))
        .filter(uniqueBy('ean'))

      const activeSubscriptions = subscriptionLicenses.map(license => {
        return StaticSubscriptions.find((subscription) => subscription.ean === license.ean)
      })

      const subscriptionGrades = activeSubscriptions
        .map((subscription) => subscription?.grades)
        .filter(unique)
        .sort()

      const shownSubscriptions: SubscriptionDetails[] = []
      subscriptionGrades.map((grades) => {
        const premiumSubscription = activeSubscriptions
          .find((subscription) => subscription?.type === 'premium' && subscription?.grades === grades)
        const plusSubscription = activeSubscriptions
          .find((subscription) => subscription?.type === 'plus' && subscription?.grades === grades)
        const basicSubscription = activeSubscriptions
          .find((subscription) => subscription?.type === 'basic' && subscription?.grades === grades)
        const individualSubscription = activeSubscriptions
          .find((subscription) => subscription?.type === 'individual' && subscription?.grades === grades)
        const trialSubscription = activeSubscriptions
          .find((subscription) => subscription?.type === 'trial' && subscription?.grades === grades)

        const subscription = premiumSubscription || plusSubscription || basicSubscription || individualSubscription || trialSubscription
        if (subscription) {
          shownSubscriptions.push(subscription)
        }
      })

      shownSubscriptions.forEach((subscription) => subscription.endDate = getLatestEndDateByEan(subscription.ean))

      return shownSubscriptions.sort((a, b) => (a.grades > b.grades ? 1 : -1))
    } finally {
      isLoading.value = false
    }
  }

  const isTrial = (subscription: SubscriptionDetails): boolean => { return subscription?.type === 'trial' }
  const isBasic = (subscription: SubscriptionDetails): boolean => { return subscription?.type === 'basic' }
  const isIndividual = (subscription: SubscriptionDetails): boolean => { return subscription?.type === 'individual' }
  const isPlus = (subscription: SubscriptionDetails): boolean => { return subscription?.type === 'plus' }
  const isPremium = (subscription: SubscriptionDetails): boolean => { return subscription?.type === 'premium' }
  const isPlusOrPremium = (subscription: SubscriptionDetails): boolean => { return isPlus(subscription) || isPremium(subscription) }

  return {
    setSubscriptionsDetails,
    isTrial,
    isBasic,
    isIndividual,
    isPlus,
    isPremium,
    isLoading,
    isPlusOrPremium,
  }
}

export default useSubscription
